import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import PromotionModel from "./PromotionModel";
import CategoryCardListModel from "./CategoryCardListModel";
import BioCardListModel from "./BioCardListModel";
import IEnrichment from "../interfaces/IEnrichment";
import FormModel from "./FormModel";

export default class BiographyPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly hero?: null | Readonly<PromotionModel>;
    public readonly relatedTools?: null | Readonly<CategoryCardListModel>;
    public readonly bioCards?: null | Readonly<BioCardListModel[]>;
    public readonly form?: null | Readonly<FormModel>;

    constructor(props:Readonly<BiographyPageModel> | null | undefined) {
        const {title, description, htmlId, templateType, navigationTheme, enrichments = [], keywords = [], 
            hero, relatedTools, bioCards = [], form} = props || {};
        this.htmlId = htmlId;
        this.templateType = templateType || null;
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description || null;
        this.title = title || null;
        this.hero = new PromotionModel(hero ?? null);
        this.relatedTools = new CategoryCardListModel(relatedTools ?? null);
        this.bioCards =  bioCards?.map(bioCardList => new BioCardListModel(bioCardList));
        this.form = form ? new FormModel(form) : null;
    }
}

