import CategoryCardListModel from "./CategoryCardListModel";
import PromotionModel from "./PromotionModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ImageModel from "./ImageModel";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import TabsTallModel from "./TabsTallModel";
import DisclosureItemModel from "./DisclosureItemModel";
import IEnrichment from "../interfaces/IEnrichment";

export default class LandingPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly hero?: null | Readonly<PromotionModel>;
    public readonly category?: null | Readonly<CategoryCardListModel>;
    public readonly tallTabs?: null | Readonly<TabsTallModel> | undefined;
    public readonly backgroundImage?: ImageModel | null = null;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly disclosures?: DisclosureItemModel[];

    constructor(props:Readonly<LandingPageModel> | null | undefined) {
        const {htmlId, templateType, hero, tallTabs, category, navigationTheme, title, description, enrichments = [], keywords = [], disclosures = []} = props || {};
        this.htmlId = htmlId;
        this.tallTabs = new TabsTallModel(tallTabs);
        this.templateType = templateType || null;
        this.hero = new PromotionModel(hero ?? null);
        this.category = new CategoryCardListModel(category ?? null);
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description || null;
        this.title = title || null;
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
    }
}

