import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ImageListModel from "./ImageListModel";
import ImageListItemModel from "./ImageListItemModel";
import ImageModel from "./ImageModel";
import DisclosureItemModel from "./DisclosureItemModel";
import LocatorModel from "./LocatorModel";
import ContentBlockModel from "./ContentBlockModel";
import IEnrichment from "../interfaces/IEnrichment";

interface LocationsPageJSON {
    readonly _clientType?: string
    readonly templateType: PageTemplateEnum | null;
    readonly htmlId?: string | undefined
    readonly navigationTheme: ThemeTypeEnum | null
    readonly enrichments: IEnrichment[] | null;
    readonly keywords: IKeyword[] | null;
    readonly description: string | null;
    readonly title: string | null;
    readonly contentBlock?: ContentBlockModel | null | undefined;
    readonly imageList?: ImageListItemModel[] | null | undefined;
    readonly disclosures?: DisclosureItemModel[];
    readonly locator?: LocatorModel;
    readonly locations: object[];
}

export default class LocationsPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly locator?: LocatorModel | undefined;
    public readonly locations: object[];
    public readonly contentBlock?: ContentBlockModel | null | undefined;
    public readonly imageList?: null | Readonly<ImageListModel>;
    public readonly disclosures?: DisclosureItemModel[];

    constructor(props:Readonly<LocationsPageJSON> | null | undefined) {
        const {title, description, htmlId, templateType, locator, contentBlock, enrichments = [], keywords = [], imageList, disclosures = [], locations = []} = props || {};
        this.imageList = new ImageListModel({
            content: imageList?.map(i=>{
                return new ImageListItemModel({image: new ImageModel(i), htmlId:"", subText:""})
             }) ?? []
        });
        this.htmlId = htmlId;
        this.templateType = templateType ?? null;
        this.navigationTheme = ThemeTypeEnum.Dark;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description ?? null;
        this.title = title ?? "Vystar Credit Union";
        this.locator = locator;
        this.contentBlock = contentBlock;        
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
        this.locations = locations || [];
    }
}

