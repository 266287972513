import { useEffect, useState } from 'react';
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";
import { useReactToPrint } from 'react-to-print';
import { MenuItem } from "@mui/material";
import IComponentProps from "../../../interfaces/IComponentProps";
import './RatesFilter.module.scss';
import ratesFilterConfig from "./ratesFilterConfig";
import RateFilterModel from "../../../models/RateFilterModel";
import RateGroupModel from "../../../models/RateGroupModel";
import SmartLink from "../_shared/SmartLink/SmartLink";
import LinkTypeEnum from "../../../enums/LinkTypeEnum";
import withErrorBoundary from '../../_shared/helpers/withErrorBoundary';
import DataTable from "./DataTable";
import RateAccountModel from '../../../models/RateAccountModel';
import "../../../App/_styles/component-helpers/_rates-table.scss";
import DisclosureItemModel from "../../../models/DisclosureItemModel";
import ContentfulReactRenderOptions from "../../_shared/ContentfulReactRenderOptions";
import ErrorBoundary from "../_shared/ErrorBoundary/ErrorBoundary";
import Select from '../_shared/Select/Select';
import { PrintButton } from '../PrintButton/PrintButton';
import Logo from '../../pages/Header/Logo/Logo';
import LegalText from './LegalText';
import { useNavigate } from "react-router-dom";

interface IIframeData{
    [key:string]: string;
}
// goes through each account and looks for the latest effective date
const latestDate = (accounts: RateAccountModel[]) => {
    let latestEffectDate: Date = new Date(0);
    accounts.forEach((account: RateAccountModel)=>{
        const effectiveDate = new Date((account.effectiveDate ?? "1/1/1970") + "T12:00:00");
        if(effectiveDate > latestEffectDate){
            latestEffectDate = effectiveDate;
        }
    });
    return latestEffectDate;
}
// goes through each account, finds the specific array that needs reducing, and returns the unique itemss by using their name
const uniqueDisclosuresFromProperty = (accounts: RateAccountModel[], propertyName: string) => {
    const disclosures: DisclosureItemModel[] = [];
    const disclosureNames: string[] = [];
    accounts.forEach((account: any)=>{
        const disclosureItem = account[propertyName] ?? [];
        disclosureItem.forEach((disclosure: DisclosureItemModel)=>{
            const { name } = disclosure;
            if(!disclosureNames.includes(name)){
                disclosureNames.push(name);
                disclosures.push(disclosure);
            }
        });
    });
    return disclosures;
}

export function RatesFilterRender(props: (RateFilterModel & IComponentProps)){

    let componentRef: any;
    let {ratesGroup:ratesGroups, description, mainCallToAction, bottomCallsToAction, disclosures, id, name, htmlId} = props || [];
    let allFilterSelection = "All";
    let groupNames = ratesGroups?.map(group=>group?.accountGroupName || ""); 

    // IFRAME START

    let iframeData: IIframeData = {};

    const [ shouldUseIframe, setShouldUseIframe] = useState(false);
    ratesGroups?.forEach(group=>{
        const iframeKey = group.accountGroupName;
        const iframeValue = group.iframeURL;
        if(iframeKey && iframeValue){
            iframeData[iframeKey] = iframeValue;
        }
    });
    const allRatesArray = Object.values(iframeData);
    const [ iframeArray, setIframeArray ] = useState<string[]>(allRatesArray);
    useEffect(()=>{
        if(allRatesArray.length >= 1){
            setShouldUseIframe(true);
        }
    },[componentRef, allRatesArray]);

    // IFRAME END

    const [selectedGroupName, setSelectedGroupName] = useState(allFilterSelection);

    // TABLE DATA START 

    let {filterLabel, accounts = []}  = ratesGroups?.find(group => selectedGroupName === group?.accountGroupName)
    || ratesGroups.reduce((accumulator={}, currentGroup)=>{
        // uses accumulator for the all case
        return {
            filterLabel: accumulator.filterLabel ?? currentGroup.filterLabel,
            accounts: (accumulator.accounts || []).concat(currentGroup.accounts || []),
        } as RateGroupModel;
    });
    let pageConfig = ratesFilterConfig.entries().find((e:any) => e.value.relativeUrl === window.location.pathname)?.value;
    let {headerTitles = [], fnAccountRowSelector} = pageConfig || {};
    const fnRowReduce = (accumulator: Array<string[]>, account:RateAccountModel)=> {
        let accountRows = account?.rateLines?.map((rate, index) =>
            fnAccountRowSelector ? fnAccountRowSelector(account?.accountType || "--", rate, index) : []);
        return accumulator.concat(accountRows || []);
    };
    const bodyRows:(string[])[] = accounts.reduce(fnRowReduce,[]);

    // TABLE DATA END
    
    // EVENT HANDLERS

    const navigate = useNavigate();
    const onPageChange = (e: any)=> navigate(e.target.value);
    const onGroupChange = (e: any)=> {
        setSelectedGroupName(e.target.value);
        const iframeURL = iframeData[e.target.value];
        if(e.target.value === "All" && allRatesArray.length){
            setIframeArray(allRatesArray);
            setShouldUseIframe(true);
        }else{
            if(iframeURL){
                setShouldUseIframe(true);
                setIframeArray([iframeURL]);
            }else{
                setShouldUseIframe(false);
            }
        }
    }
    const handlePrint = useReactToPrint({
        content: () => componentRef
      });

    // LEGAL TEXTS

    const effectiveDate: Date = latestDate(accounts);
    const accountsDisclosures = uniqueDisclosuresFromProperty(accounts, "disclosures");
    const legalTexts = uniqueDisclosuresFromProperty(accounts, "legalTexts");
    const legalTextAdjust = !!legalTexts?.length ? "align-unset" : "";
    const isCreditCard = !!(name?.includes("Credit Card") || htmlId?.includes("CreditCard"));
    
    return (<div className="layout hero-filter" data-contentid={id} id={htmlId}>
        <div className="container" ref={node=>componentRef=node}>
            <div className="row mt-3 w-75 only-print">
                <Logo useStaticImage={true} />
            </div>
            <div className="row justify-content-center text-center dont-print">
                <div className="col-12 col-lg-8 col-xl-6">
                    { <h1 className="visually-hidden">{pageConfig?.label}</h1>}
                    <Select
                        onChange={onPageChange}
                        value={window.location.pathname}
                        className={"pageFilter"}
                    >
                        {ratesFilterConfig.values().map(({label, relativeUrl}) =>{
                            return <MenuItem key={label} value={relativeUrl}>{label}</MenuItem>;
                        })}
                    </Select>
                </div>
            </div>
            <div className="row justify-content-center g-0 mb-4 dont-print">
                <div className="col">
                    <div className="desc-text">
                        <div className="text-center">
                            {description && documentToReactComponents(description, ContentfulReactRenderOptions)}
                            <SmartLink {...mainCallToAction} type={LinkTypeEnum.PrimaryButton}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="rates-filter-container">
                    <div className="rates-filters">
                        <div className="filter">
                            <label htmlFor="filter-vehicle-type" aria-label={`Select ${filterLabel}`}>{filterLabel}</label>
                            <Select onChange={onGroupChange}
                                    defaultValue={allFilterSelection ?? ""}
                                    name="filter-select"
                                    id="filter-vehicle-type"
                                    ariaLabel={`Select ${filterLabel}`}
                            >
                                <MenuItem value={allFilterSelection ?? ""}>{allFilterSelection}</MenuItem>;
                                {groupNames?.map(label => {
                                    return <MenuItem key={label} value={label ?? ""}>{label}</MenuItem>;
                                })};
                            </Select>
                        </div>
                    </div>
                    {!shouldUseIframe && <div className="disclosure-note" style={{marginBottom:0, paddingTop:0, paddingBottom:0}}>
                        <div className="legal-text col-lg-8">
                            <LegalText legalTexts={legalTexts}/>
                        </div>
                        <div className={`d-flex flex-row justify-content-around col-lg-4 effective-date ${legalTextAdjust}`}>
                            <div>
                                {effectiveDate ? `Effective Date ${'\u00A0'} ${effectiveDate.toLocaleDateString()} ${'\u00A0'}` : ""}
                            </div>
                            <div className="dont-print">
                                <PrintButton handlePrint={handlePrint} />
                            </div>
                        </div>
                    </div>}
                    <div className={"scrollable table-small"}>
                        {shouldUseIframe ? iframeArray.map((iframeURL:string, key: number)=>{
                            return (
                                    <iframe 
                                        key={key}
                                        width="319px" 
                                        height="577px" 
                                        title={key.toString()}
                                        src={iframeURL}
                                        className="ms-3">
                                    </iframe>  
                            );
                        }): <DataTable {...{headerTitles}} {...{bodyRows}} className={"rates expandable"} isCreditCard={isCreditCard} />}
                    </div>
                    <div className="text-center mt-5 cta-row dont-print">
                        {bottomCallsToAction?.map((link)=>{
                            return <SmartLink key={link?.url} {...link}/>;
                        })}
                    </div>
                </div>

            </div>
            <div className="row">
                <div className="col-12">
                    <ErrorBoundary>
                        <div className="disclosure-block">
                            <LegalText legalTexts={accountsDisclosures.concat(disclosures ?? [])} />
                        </div>
                    </ErrorBoundary>
                </div>
            </div>
        </div>
    </div>);
}
export default withErrorBoundary(RatesFilterRender);
