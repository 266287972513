import PromotionModel from "./PromotionModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import BlogNewsEventModel from "./BlogNewsEventModel";
import BlogNewsEventsItemModel from "./BlogNewsEventsItemModel";
import IEnrichment from "../interfaces/IEnrichment";

export default class BlogNewsEventsPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly hero?: null | Readonly<PromotionModel>;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly contents?: string[] | undefined;
    public readonly bneItems?: BlogNewsEventModel[][];
    public readonly content?: any;
    public readonly defaultTab?: string;

    constructor(props:Readonly<BlogNewsEventsPageModel> | null | undefined) {
        const { htmlId, templateType, hero, navigationTheme = ThemeTypeEnum.Dark, title, description, 
            enrichments = [], keywords = [], contents, bneItems = [], content, defaultTab} = props || {};

        this.htmlId = htmlId;
        this.templateType = templateType ?? null;
        this.hero = new PromotionModel(hero ?? null);
        this.navigationTheme = !content ? navigationTheme : ThemeTypeEnum.Dark;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description ?? null;
        this.title = title ?? null;
        this.contents = contents;
        this.bneItems = bneItems;
        this.content = content;
        this.defaultTab = defaultTab;
    }
}

