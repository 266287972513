
import RatesPageFilterEnum from "../../../enums/RatesPageFilterEnum";

import {
    IRatePersonalAutoVehicleTerm,
    IRateBusinessDeposit,
    IRateBusinessLoan,
    IRateCD,
    IRatePersonalChecking,
    IRatePersonalMortgageLoan,
    IRateLine,
    IRatePersonalCreditCard,
    IRatePersonalHomeEquityAndLineOfCredit,
    IRatePersonalLoanAndLineOfCredit,
    IRatePersonalSavingsIraHsaRates
} from "../../../models/RateLineModel"
import createAssociativeArray from "../../../redux/_shared/helpers/createAssociativeArray";

interface IRatesFilterConfigData{
    relativeUrl: string,
    label: string,
    headerTitles: string[],
    fnAccountRowSelector: (accountType: string, rate:IRateLine, index: number) => string[]
}

const ratesFilterConfig = createAssociativeArray<RatesPageFilterEnum, IRatesFilterConfigData>()
    .set(RatesPageFilterEnum.AutoAndVehicle, {label:"Auto & Vehicle Loans",
        relativeUrl: "/personal/rates/auto-and-vehicle-loan-rates",
        headerTitles:["Account Type", "Term", "APR"],
        fnAccountRowSelector: (accountType: string, rate:IRatePersonalAutoVehicleTerm, index: number)=>{
            let {apyApr, term} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                term || "-",
                apyApr || "-"
            ];
        }
    })
    .set(RatesPageFilterEnum.BusinessDeposit, {label:"Business Deposits",
        relativeUrl: "/business/rates/business-deposit-account-rates",
        headerTitles:["Account Type", "Dividend Rate", "APY", "Minimum Balance to Earn Dividends",
            "Monthly Service Charge", "Minimum Daily Balance to Avoid Monthly Service Charge"],
        fnAccountRowSelector: (accountType: string, rate:IRateBusinessDeposit, index: number)=>{
            let {dividendRate, apyApr, minBalanceToEarnDividends, monthlyServiceCharge, minDailyBalanceToAvoidServiceCharge} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                dividendRate || "-",
                apyApr || "-",
                minBalanceToEarnDividends || "-",
                monthlyServiceCharge || "-",
                minDailyBalanceToAvoidServiceCharge || "-"
            ];
        }
    })
    .set(RatesPageFilterEnum.BusinessLoanAndCredit, {label:"Business Loans",
        relativeUrl: "/business/rates/business-loan-and-credit-rates",
        headerTitles:["Account Type", "APR"],
        fnAccountRowSelector: (accountType: string, rate:IRateBusinessLoan, index: number)=>{
            let {apyApr} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                apyApr || "-"
            ];
        }
    })
    .set(RatesPageFilterEnum.PersonalCDs, {label:"Personal CDs",
        relativeUrl: "/personal/rates/cd-rates",
        headerTitles:["CD Term", "Minimum Balance to Earn Dividends", "Dividend Rate (APR)", "APY"],
        fnAccountRowSelector: (accountType: string, rate:IRateCD, index: number)=>{
            let {minBalanceToEarnDividends, dividendRate, apyApr} = rate;
            return [
                accountType || "-",
                minBalanceToEarnDividends || "-",
                dividendRate || "-",
                apyApr || "-"
            ];
        }
    })
    .set(RatesPageFilterEnum.BusinessCDs, {label:"Business CDs",
        relativeUrl: "/business/rates/cd-rates",
        headerTitles:["CD Term", "Minimum Balance to Earn Dividends", "Dividend Rate (APR)", "APY"],
        fnAccountRowSelector: (accountType: string, rate:IRateCD, index: number)=>{
            let {minBalanceToEarnDividends, dividendRate, apyApr} = rate;
            return [
                accountType || "-",
                minBalanceToEarnDividends || "-",
                dividendRate || "-",
                apyApr || "-"
            ];
        }
    })
    .set(RatesPageFilterEnum.Checking, {label:"Checking",
        relativeUrl: "/personal/rates/checking-account-rates",
        headerTitles:["Account Type", "Minimum Balance to Earn Dividends", "Dividend Rate", "APY"],
        fnAccountRowSelector: (accountType: string, rate:IRatePersonalChecking, index: number)=>{
            let {minBalanceToEarnDividends, dividendRate, apyApr} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                minBalanceToEarnDividends || "-",
                dividendRate || "-",
                apyApr || "-"
            ]
        }
    })
    .set(RatesPageFilterEnum.CreditCard, {label:"Credit Card",
        relativeUrl: "/personal/rates/credit-card-rates",
        headerTitles:["Account Type", "APR"],
        fnAccountRowSelector: (accountType: string, rate:IRatePersonalCreditCard, index: number)=>{
            let {apyApr} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                apyApr || "-"
            ]
        }
    })
    .set(RatesPageFilterEnum.HomeEquityLoanAndLOC, {label:"Home Equity",
        relativeUrl: "/personal/rates/home-equity-loan-and-line-of-credit-rates",
        headerTitles:["Account Type", "Combined Loan to Value", "Term", "Rate", "APR", "Points", "P&I Payments"],
        fnAccountRowSelector: (accountType: string, rate:IRatePersonalHomeEquityAndLineOfCredit, index: number)=>{
            let {combinedLoanToValue, term, apyApr, initialRate, points, principleAndInterestPayments} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                combinedLoanToValue || "-",
                term || "-",
                initialRate || "-",
                apyApr || "-",
                points || "-",
                principleAndInterestPayments || "-"
            ];
        }
    })
    .set(RatesPageFilterEnum.MortgageLoan, {label:"Mortgages",
        relativeUrl: "/personal/rates/mortgage-loan-rates",
        headerTitles:["Account Type", "Term", "Initial Rate", "Points", "APR", "Fully Indexed Rate", "Maximum Interest Rate", "P&I Payments"],
        fnAccountRowSelector: (accountType: string, rate:IRatePersonalMortgageLoan, index: number)=>{
            let {term, initialRate, points, apyApr, fullyIndexedRate, maxInterestRate, principleAndInterestPayments } = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                term || "-",
                initialRate || "-",
                points || "-",
                apyApr || "-",
                fullyIndexedRate || "-",
                maxInterestRate || "-",
                principleAndInterestPayments  || "-"
            ]
        }
    })
    .set(RatesPageFilterEnum.PersonalLoanAndLOC, {
        label: "Personal Loans",
        relativeUrl: "/personal/rates/personal-loan-and-line-of-credit-rates",
        headerTitles: ["Account Type", "Term", "APR"],
        fnAccountRowSelector: (accountType: string, rate: IRatePersonalLoanAndLineOfCredit, index: number) => {
            let {apyApr, term} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                term || "-",
                apyApr || "-"
            ]
        }
    })
    .set(RatesPageFilterEnum.SavingIRAHSA, {label:"Savings",
        relativeUrl: "/personal/rates/savings-ira-hsa-rates",
        headerTitles:["Account Type", "Minimum Balance to Earn Dividends", "Dividend Rate", "APY"],
        fnAccountRowSelector: (accountType: string, rate:IRatePersonalSavingsIraHsaRates, index: number)=>{
            let {minBalanceToEarnDividends, dividendRate, apyApr} = rate;
            return [
                index === 0 ? (accountType || "-") : " ",
                minBalanceToEarnDividends || "-",
                dividendRate || "-",
                apyApr || "-"
            ];
        }
    });

export default ratesFilterConfig;