import ImageModel from "./ImageModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import AnyDynamicContentModel from "../redux/_shared/helpers/AnyDynamicContentModel.type";
import IEnrichment from "../interfaces/IEnrichment";

export default class DynamicPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly backgroundImage?: ImageModel | null = null;
    public readonly htmlId?: string = "";
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly content?: AnyDynamicContentModel[];
    constructor(props:Readonly<DynamicPageModel> | null | undefined) {
        const {title = "", navigationTheme = ThemeTypeEnum.Dark,
            backgroundImage, templateType = null, description, enrichments = [], keywords = [], content = []} = props || {};
        const {htmlId} = props || {};
        this.htmlId = htmlId;
        this.title = title;
        this.templateType = templateType;
        this.backgroundImage = new ImageModel(backgroundImage);
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description || null;
        this.title = title || null;
        this.navigationTheme = navigationTheme;
        this.content =  content || [];
    }
}

