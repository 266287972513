import PromotionModel from "./PromotionModel";
import CategoryCardListModel from "./CategoryCardListModel";
import ProductCarouselModel from "./ProductCarouselModel";
import TestimonialCarouselModel from "./TestimonialCarouselModel";
import ImageListModel from "./ImageListModel";
import ImageModel from "./ImageModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import DisclosureItemModel from "./DisclosureItemModel";
import IEnrichment from "../interfaces/IEnrichment";
import PersonalizedCategoryModel from "./PersonalizedCategoryModel";
import PersonalizedPromoModel from "./PersonalizedPromoModel";

export default class HomePageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly backgroundImage?: ImageModel | null = null;
    public readonly htmlId?: string = "";
/*     public readonly hero?: null | Readonly<PromotionModel>;
    public readonly category?: null | Readonly<CategoryCardListModel>;
 */    public readonly carousel?: null | Readonly<TestimonialCarouselModel>;
    public readonly imageList?: null | Readonly<ImageListModel>;
    public readonly largeCarousel?: null | Readonly<ProductCarouselModel>;
    public readonly mainPromo?: null | Readonly<PromotionModel>;
    public readonly secondaryPromos?: null | Array<Readonly<PromotionModel>>;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly personalizedCategory?: null | Readonly<PersonalizedCategoryModel>;
    public readonly personalizedPromo?: null | Readonly<PersonalizedPromoModel>;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly disclosures?: DisclosureItemModel[];
    public readonly shouldPersonalize?: boolean | null;
    constructor(props:Readonly<HomePageModel> | null | undefined) {
        const {title = "", mainPromo = null, secondaryPromos, navigationTheme = ThemeTypeEnum.Dark, shouldPersonalize,
            imageList, largeCarousel, carousel, backgroundImage, templateType, personalizedCategory, personalizedPromo, 
            description, enrichments = [], keywords = [], disclosures = []} = props || {};
        const {htmlId} = props || {};
        this.htmlId = htmlId;
        this.title = title;
/*         this.hero = hero ? new PromotionModel(hero): null;
        this.category = new CategoryCardListModel(category);
 */        this.imageList = new ImageListModel(imageList);
        this.carousel = new TestimonialCarouselModel(carousel)
        this.largeCarousel = new ProductCarouselModel(largeCarousel);
        this.backgroundImage = new ImageModel(backgroundImage);
        this.mainPromo = new PromotionModel(mainPromo ?? null);
        this.personalizedCategory = new PersonalizedCategoryModel(personalizedCategory);
        this.personalizedPromo = new PersonalizedPromoModel(personalizedPromo ?? null);
        this.secondaryPromos = secondaryPromos ? secondaryPromos.map(promo => new PromotionModel(promo ?? null)) : [];
        this.templateType = templateType || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description || null;
        this.title = title || null;
        this.navigationTheme = navigationTheme;
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
        this.shouldPersonalize = shouldPersonalize;
    }
}

