import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import PromoAccordionsModel from "./PromoAccordionsModel";
import AnchorsModel from "./AnchorsModel";
import CategoryCardListModel from "./CategoryCardListModel";
import DisclosureItemModel from "./DisclosureItemModel";
import AccordionsModel from "./AccordionsModel";
import IEnrichment from "../interfaces/IEnrichment";
import FormModel from "./FormModel";

export default class ContactPageModel implements IPageRequestData {
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Light;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly promo?: PromoAccordionsModel;
    public readonly form?: FormModel | null;
    public readonly anchors?: AnchorsModel;
    public readonly categoryCards?: CategoryCardListModel;
    public readonly accordions?: AccordionsModel[];
    public readonly disclosures?: DisclosureItemModel[];

    constructor(props:Readonly<ContactPageModel> | null | undefined) {
        const {title, description, htmlId, templateType, navigationTheme, enrichments = [], keywords = [], promo, form, anchors, categoryCards, accordions = [], disclosures = [] } = props || {};
        this.htmlId = htmlId;
        this.templateType = templateType || null;
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description || null;
        this.title = title || null;
        this.promo = new PromoAccordionsModel(promo ?? null);
        this.form = form ? new FormModel(form) : null;
        this.anchors = new AnchorsModel(anchors ?? null);
        this.categoryCards = new CategoryCardListModel(categoryCards ?? null);
        this.accordions =  accordions?.map(accordion => new AccordionsModel(accordion));
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
    }
}

