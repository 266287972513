import CategoryCardListModel from "./CategoryCardListModel";
import PromotionModel from "./PromotionModel";
import ProductStepwiseDetailModel from "./ProductStepwiseDetailModel";
import CTABarProductModel from "./CTABarProductModel";
import IPageRequestData, { IKeyword } from "../interfaces/IPageRequestData";
import PageTemplateEnum from "../enums/PageTemplateEnum";
import TestimonialCarouselModel from "./TestimonialCarouselModel";
import ThemeTypeEnum from "../enums/ThemeTypeEnum";
import ImageModel from "./ImageModel";
import DisclosureItemModel from "./DisclosureItemModel";
import IEnrichment from "../interfaces/IEnrichment";

export default class CategoryPageModel implements IPageRequestData{
    public readonly _clientType?: string = this.constructor.name;
    public readonly templateType: PageTemplateEnum | null;
    public readonly htmlId?: string | undefined = "";
    public readonly carousel?: null | Readonly<TestimonialCarouselModel>;
    public readonly hero?: PromotionModel | null  | undefined;
    public readonly category?: null | Readonly<CategoryCardListModel>;
    public readonly stepwise?: null | Readonly<ProductStepwiseDetailModel>;
    public readonly callToActionBar?: null | Readonly<CTABarProductModel>;
    public readonly navigationTheme: ThemeTypeEnum | null = ThemeTypeEnum.Dark;
    public readonly enrichments: IEnrichment[] | null;
    public readonly keywords: IKeyword[] | null;
    public readonly description: string | null;
    public readonly title: string | null;
    public readonly categoryRelated?: Readonly<CategoryCardListModel> | null | undefined;
    public readonly backgroundImage?: ImageModel | null = null;
    public readonly disclosures?: DisclosureItemModel[];

    constructor(props:Readonly<CategoryPageModel> | null | undefined) {
        const {htmlId, hero, category, stepwise, callToActionBar, carousel, templateType, categoryRelated, 
            backgroundImage, navigationTheme, title, description, enrichments = [], keywords = [], disclosures = []} = props || {};
        this.htmlId = htmlId;
        this.hero = new PromotionModel(hero ?? null);
        this.category = new CategoryCardListModel(category ?? null);
        this.stepwise = new ProductStepwiseDetailModel(stepwise ?? null);
        this.carousel = new TestimonialCarouselModel(carousel)
        this.callToActionBar = new CTABarProductModel(callToActionBar ?? null);
        this.templateType = templateType || null;
        this.navigationTheme = navigationTheme || null;
        this.enrichments = enrichments;
        this.keywords = keywords;
        this.description = description || null;
        this.title = title || null;
        this.categoryRelated = new CategoryCardListModel(categoryRelated ?? null);
        this.backgroundImage = new ImageModel(backgroundImage);
        this.disclosures =  disclosures?.map(disclosure => new DisclosureItemModel(disclosure));
    }
}

